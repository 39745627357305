<template>
<div>
    <!-- modal -->
    <b-modal hide-footer id="lawDailyworkDescriptionSelectionFormModal" ref="lawDailyworkDescriptionSelectionFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawDailyworkDescription.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="law-dailywork-name-input">{{ $t('lawDailyworkDescription.name') }}</label>
                    <b-form-group>
                        <b-form-select id="law-dailywork-name-input" size="sm" v-model="lawDailyworkDescriptionSelect" value-field="id" text-field="name" :options="lawDailyworkDescriptionOption" @change="addOrUpdate()"/>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawDailyworkDescriptionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            name: '',
            lawDailyworkDescriptionOption: [],
            lawDailyworkDescriptionSelect: 0,
            type: '',
        }
    },
    methods: {
        clear() {
            this.lawDailyworkDescriptionSelect = 0;
        },
        showInfo() {
            this.$refs['lawDailyworkDescriptionSelectionFormModal'].show()
            const controller = {
                name: 'LawDailyworkDescription'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawDailyworkDescriptionOption = response.data.lawDailyworkDescriptionList
                    this.lawDailyworkDescriptionOption.splice(0, 0, this.$nullSelected())
                    this.lawDailyworkDescriptionSelect = 0
                } else {
                    this.$bvModal.hide('lawDailyworkDescriptionSelectionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async addOrUpdate() {
            if(this.lawDailyworkDescriptionSelect > 0){
                await this.$emit("lawDailyworkDescriptionEmitValue", this.lawDailyworkDescriptionOption.find(p=> p.id == this.lawDailyworkDescriptionSelect))
                this.$bvModal.hide('lawDailyworkDescriptionSelectionFormModal')
            }
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.b-modal {
    z-index: 1050 !important;
}
</style>
