<template>
<div>
    <!-- modal -->
    <b-modal id="clientsFormModal" ref="clientsFormModal" centered size="xl" no-close-on-backdrop :title=" $t('clients.info')" hide-footer>
        <reminder-form ref="reminderForm"></reminder-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-button v-if="this.id != 0" variant="outline-primary" size="sm" class="mr-1" @click.prevent="clientsConflictCheck">
                        {{$t('others.ok_title')}}
                    </b-button>
                    <b-button v-if="this.id != 0" variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-8 mt-2">
                        <b-form>
                            <label for="clients-name-input">{{ $t('clients.name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('clients.name')" rules="required">
                                    <b-form-input disabled size="sm" id="clients-name-input" v-model="name" maxlength="250" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-2">
                        <b-row class="mt-1">
                            <div class="demo-inline-spacing">
                                <b-form-checkbox disabled v-model="isAgent" :state="state" name="checkbox-validation">
                                    {{ $t('clients.agent') }}
                                </b-form-checkbox>
                                <b-form-checkbox disabled v-model="isOwner" :state="state" name="checkbox-validation">
                                    {{ $t('clients.owner') }}
                                </b-form-checkbox>
                            </div>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="clients-owner-code-input">{{ $t('clients.owner_code') }}</label>
                            <b-form-group>
                                <cleave disabled style="height: 2em;" id="clients-owner-code-input" v-model="ownerCode" class="form-control" :raw="false" :options="ownerCodeOptions" placeholder="000000" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="clients-address-input">{{ $t('clients.address') }}</label>
                        <b-form-textarea disabled id="clients-address-input" :placeholder=" $t('clients.address')" size="sm" rows="3" v-model="address" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="clients-country-input">{{ $t('clients.country') }}</label>
                            <b-form-select id="clients-country-input" disabled :placeholder="$t('clients.country')" size="sm" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-1">
                        <label for="clients-contact-input">{{ $t('clients.contact') }}</label>
                        <b-form-input disabled id="clients-contact-input" v-model="contact" size="sm" maxlength="100" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-1">
                        <label for="clients-responsible-input">{{ $t('clients.responsible') }}</label>
                        <b-form-input disabled id="clients-responsible-input" v-model="responsible" size="sm" maxlength="100" />
                    </b-col>
                </b-row>
                <b-row :hidden="isOwner == false">
                    <b-col class="mt-1">
                        <b-form>
                            <label for="clients-power-of-attorney-deadline-date-input">{{ $t('clients.power_of_attorney_deadline_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('clients.power_of_attorney_deadline_date')" size="sm" v-model="powerOfAttorneyDeadlineDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenClientControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('clients.accounting')" style="width:100%;">
                            <b-card-text>
                                <clients-accounting-list ref="clientAccountingList"></clients-accounting-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.matter_document')" style="width:100%;">
                            <b-card-text>
                                <matter-document-list ref="documentList" :getClientsList="getClientsList"></matter-document-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('clients.invoices')" style="width:100%;">
                            <b-card-text>
                                <client-invoice-list :lawClientId="this.id" ref="clientInvoiceList" :getClientsList="getClientsList"></client-invoice-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('clients.invoice_codes')" style="width:100%;">
                            <b-card-text>
                                <clients-invoice-code-list :lawClientId="this.id" ref="clientsInvoiceCodeList" :getClientsList="getClientsList"></clients-invoice-code-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getClientsList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            name: '',
            ownerCode: null,
            ownerCodeOptions: {
                blocks: [6],
                numericOnly: true
            },
            wordTemplateList: [],
            mailTemplateList: [],
            isOwner: false,
            isAgent: false,
            working: false,
            annuity: false,
            compensationFee: false,
            patentCertificate: false,
            address: '',
            contact: '',
            responsible: '',
            powerOfAttorneyDeadlineDate: null,
            hiddenClientControl: true,
            countrySelect: 0,
            countryOption: [],
        }
    },
    computed: {
        state() {
            return this.isAgent || this.isOwner
        },
    },
    methods: {
        async getReminderInfo() {
            this.$refs.reminderForm.showInfo(0, this.id, 1)
        },
        showInfo(id) {
            this.$refs['clientsFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenClientControl = true
            } else {
                this.hiddenClientControl = false
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawClient'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.clientId = response.data.lawClient.clientId
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.name = response.data.lawClient.client
                    this.isAgent = response.data.lawClient.isAgent
                    this.isOwner = response.data.lawClient.isOwner
                    this.ownerCode = response.data.lawClient.ownerCode
                    this.address = response.data.lawClient.clientAddress
                    this.contact = response.data.lawClient.clientContact
                    this.responsible = response.data.lawClient.clientResponsible
                    this.powerOfAttorneyDeadlineDate = response.data.lawClient.powerOfAttorneyDeadlineDate
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())
                    if (response.data.lawClient.countryId > 0) {
                        this.countrySelect = response.data.lawClient.countryId
                    }

                    this.$refs.clientAccountingList.getClientsAccountingList(this.clientId)
                    this.$refs.documentList.getMatterDocumentList(1, this.clientId)
                    this.$refs.clientsInvoiceCodeList.getClientsInvoiceCodeList(this.clientId)
                } else {
                    this.$bvModal.hide('clientsFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clientsConflictCheck() {
            if (this.id > 0) {
                this.addOrUpdate()
            } else {
                this.$refs.simpleRules.validate().then(success => {
                    if (this.isAgent == true || this.isOwner == true) {
                        if (success) {
                            const data = {
                                string: this.name
                            }
                            const controller = {
                                name: 'ClientConflict',
                                actionName: 'GetClientConflictListByClientName'
                            }
                            const payload = {
                                data: data,
                                controller: controller
                            }
                            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.clientConflictList.length > 0) {
                                    // Müvekkil Çıkar Çatışması için kontrol içine mesaj basılması gerekiyor bunu nasıl yapacağımızı konuşmalıyız . 
                                    this.$ClientConflictControl(response.data.resultMessage).then(result => {
                                        if (result.value) {
                                            this.addOrUpdate()
                                        }
                                    })
                                } else {
                                    this.addOrUpdate()
                                }
                            })
                            if (this.id == 0) {
                                this.id = response.data.clientId
                                this.hiddenClientControl = false
                            }
                        }
                    } else {
                        this.$isOwnerOrAgent()
                    }
                })
            }

        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                isAgent: this.isAgent,
                isOwner: this.isOwner,
                ownerCode: this.ownerCode,
                powerOfAttorneyDeadlineDate: this.powerOfAttorneyDeadlineDate
            }
            const controller = {
                name: 'LawClient'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (this.isAgent == true || this.isOwner == true) {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('clientsFormModal')
                                this.getClientsList()
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                } else {
                    this.$isOwnerOrAgent()
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
