<template>
<div>
    <invoice-form :getClientInvoiceList="getClientInvoiceList" ref="invoiceFormModal"></invoice-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="invoice-debit-no-input">{{ $t('invoice.debit_no') }}</label>
                <b-form-group>
                    <b-form-input v-on:keyup.enter="getClientInvoiceList()" size="sm" v-model="debitNo" :placeholder="$t('invoice.debit_no')" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
            <b-col class="col-md-2">
                <label for="invoice-matter-no-input">{{ $t('invoice.matter_no') }}</label>
                <b-form-group>
                    <b-form-input v-on:keyup.enter="getClientInvoiceList()" size="sm" v-model="lawMatterNo" :placeholder="$t('invoice.matter_no')" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
            <!--
            <b-col class="col-md-2">
                <label for="invoice-agent-name-input">{{ $t('invoice.agent_name') }}</label>
                <b-form-group>
                    <b-form-input v-on:keyup.enter="getClientInvoiceList()" size="sm" v-model="invoiceClient" :placeholder="$t('invoice.agent_name')" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col> -->
            <b-col class="col-md-2">
                <label for="invoice-employee-input">{{ $t('invoice.employee') }}</label>
                <b-form-select @change="getClientInvoiceList()" id="invoice-employee-input" :placeholder="$t('invoice.employee')" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
            </b-col>
            <b-col class="col-lg-2">
                <b-form-group>
                    <label for="invoice-status-input">{{ $t('invoice.status') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-select @change="getClientInvoiceList()" id="invoice-status-input" :placeholder="$t('invoice.status')" size="sm" v-model="statusSelect" value-field="id" text-field="name" :options="statusOption" />
                    </div>
                </b-form-group>
            </b-col>
            <b-col class="col-md-4 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getClientInvoiceList()">{{ $t('invoice.list') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="clientInvoiceTable" ref="clientInvoiceTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'debitNo'" class="text-nowrap">
                {{ $t('invoice.debit_no') }}
            </span>
            <span v-else-if="props.column.field === 'lawMatterRecordNo'" class="text-nowrap">
                {{ $t('invoice.matter_no') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceClient'" class="text-nowrap">
                {{ $t('invoice.agent_name_accounting_no') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('invoice.amount') }}
            </span>
            <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                {{ $t('invoice.currency_code') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('invoice.invoice_date') }}
            </span>
            <span v-else-if="props.column.field === 'lawInvoiceStatus'" class="text-nowrap">
                {{ $t('invoice.status') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('invoice.employee') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'date'">
                <span style="font-size: smaller;">
                    {{$formatFn(props.row.date) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'total'">
                <span style="font-size: smaller;">
                    {{$formatMoney(props.row.total) }}
                </span>
            </span>

            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- STOCK 1420 
                    <span v-if="props.row.lawInvoiceStatusId == 1">
                        <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </span>-->
                    <!-- End Of Code : If lawInvoiceStatusId changes in table code need to be changed too.  -->
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getClientInvoiceList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        lawClientId: {
            type: Number
        }
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('invoice.debit_no'),
                    field: `debitNo`,
                },
                {
                    label: this.$t('invoice.matter_no'),
                    field: `lawMatterRecordNo`,
                },
                {
                    label: this.$t('invoice.agent_name_accounting_no'),
                    field: `invoiceClient`,
                },
                {
                    label: this.$t('invoice.amount'),
                    field: `total`,
                },
                {
                    label: this.$t('invoice.currency_code'),
                    field: `currency`,
                },
                {
                    label: this.$t('invoice.invoice_date'),
                    field: `date`,
                },
                {
                    label: this.$t('invoice.status'),
                    field: `lawInvoiceStatus`,
                },
                {
                    label: this.$t('invoice.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '6em'
                },
            ],
            rows: [],
            searchTerm: '',
            debitNo: '',
            lawMatterNo: '',
            invoiceClient: '',
            employeeSelect: 0,
            employeeOption: [],
            invoiceClientAccountingNo: '',
            statusSelect: 0,
            statusOption: [],
            paid: true,
            notPaid: false,
            count: ''
        }
    },
    mounted() {
        this.getClientInvoiceList()
    },
    methods: {
        async deleted(data) {
            this.count = data.lawInvoiceDetailCount
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteInvoiceConfirm(this.count).then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getClientInvoiceList()
                        } else {
                            if (response.data.resultMessage == 'ThereIsAInvoiceDetailInThisRecord') {
                                this.$InvoiceDetailError()
                            } else {
                                this.$CustomWarningAlert(response.data.resultMessage)
                            }
                            this.getClientInvoiceList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.invoiceFormModal.showInfo(id)
        },
        getClientInvoiceList() {
            this.rows = []
            const controller = {
                name: 'LawInvoice',
                actionName: 'GetLawInvoiceListByClient'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                debitNo: this.debitNo,
                lawMatterNo: this.lawMatterNo,
                lawInvoiceTypeId: 0,
                employeeId: this.employeeSelect,
                lawInvoiceStatusId: this.statusSelect,
                lawClientId: this.lawClientId
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.employeeOption = response.data.employeeList
                    this.statusOption = response.data.lawInvoiceStatusList
                    response.data.lawInvoiceList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.statusOption.splice(0, 0, this.$nullSelected()),
                        this.employeeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawInvoice',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Invoice", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
